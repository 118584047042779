/*
 * Copyright (C) FLATFEECIO - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Matthew Woods mattwoods9170@gmail.com, April 2023
 *
 * This file is part of the FLATFEECIO Calculator project, owned by Don Rudolph.
 *
 * Unauthorized use of this file may result in legal consequences.
 * Proprietary and confidential information. Unauthorized use, reproduction,
 * or distribution of this file or any portion of it may be subject to civil
 * and criminal penalties, and the party responsible could be prosecuted.
 *
 * This file is provided without warranty. Use at your own risk.
 */

import AppBar from "@mui/material/AppBar";
import Link from "@mui/material/Link";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";

const smallTitle =
  "Today's Fixed Dollar Fee vs. Legacy Percentage on Asset Fee";
// const largeTitle =
//   "Today's Fixed Dollar Fee vs. Legacy Percentage on Asset Advisor Fee";

function Header(): React.JSX.Element {
  return (
    <>
      <Link href="https://flatfeecio.com/">
        <AppBar
          position="static"
          sx={{
            textAlign: "center",
          }}
        >
          <Toolbar sx={{ flexWrap: "wrap" }}>
            <Typography
              variant="h5"
              color="inherit"
              sx={{
                fontWeight: "bold",
                flexGrow: 1,
                m: 0.5,
                p: 0.5,
                fontSize: {
                  xs: "1.35rem",
                  sm: "1.5rem",
                  md: "1.75rem",
                  lg: "2rem",
                },
              }}
            >
              {smallTitle}
            </Typography>
          </Toolbar>
        </AppBar>
      </Link>
    </>
  );
}

export default Header;
