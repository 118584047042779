/*
 * Copyright (C) FLATFEECIO - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Matthew Woods mattwoods9170@gmail.com, April 2023
 *
 * This file is part of the FLATFEECIO Calculator project, owned by Don Rudolph.
 *
 * Unauthorized use of this file may result in legal consequences.
 * Proprietary and confidential information. Unauthorized use, reproduction,
 * or distribution of this file or any portion of it may be subject to civil
 * and criminal penalties, and the party responsible could be prosecuted.
 *
 * This file is provided without warranty. Use at your own risk.
 */

"use client";

import Container from "@mui/material/Container";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/next";
import { Roboto } from "next/font/google";
import { usePathname } from "next/navigation";
import Script from "next/script";
import React from "react";

import Footer from "@/components/Layout/Footer";
import Header from "@/components/Layout/Header";
import { SubmissionContext } from "@/components/SubmissionContext";
import ThemeRegistry from "@/components/ThemeRegistry/ThemeRegistry";

const roboto = Roboto({
  weight: ["300", "400", "500", "700"],
  subsets: ["latin"],
  display: "swap",
});
const defaultTitle = "Flat Fee CIO | Calculator";
const titleMap = new Map([
  ["/", defaultTitle],
  ["/savings", "Flat Fee CIO | Savings"],
  ["/results", "Flat Fee CIO | Results"],
]);

export default function RootLayout({
  children,
}: {
  children: React.ReactNode;
}): React.JSX.Element {
  const [submission, setSubmission] = React.useState({});
  const submissionValue = React.useMemo(
    () => ({ submission, setSubmission }),
    [submission, setSubmission],
  );
  // const GA_MEASUREMENT_ID = "G-1PBDZ7F6LQ";
  const pathname = usePathname();
  const title = titleMap.get(pathname) || defaultTitle;

  return (
    <html lang="en">
      <link rel="shortcut icon" href="images/coin-128x128.png" />
      <meta
        property="og:image"
        content="https://static.fmgsuite.com/media/images/0fbf081d-183f-4e56-8bff-944869f2b11a.jpg"
        // content="/images/alt-logo.jpeg"
      />
      {/* <meta property="og:title" content={title} /> */}
      <meta property="og:title" content="Flat Fee CIO Calculator" />
      <meta name="theme-color" content="#01597C" />
      <title>{title}</title>
      <meta
        name="description"
        content="Our short survey can help you unlock your potential for tens of thousands of dollars in annual savings and higher net investment income year after year."
      />
      <meta
        property="og:description"
        content="Our short survey can help you unlock your potential for tens of thousands of dollars in annual savings and higher net investment income year after year."
      />
      <meta property="og:url" content="https://rescueyourincome.com" />
      <meta
        property="twitter:image"
        // content="https://static.fmgsuite.com/media/images/0fbf081d-183f-4e56-8bff-944869f2b11a.jpg"
        content="/images/alt-logo.jpeg"
      />
      <meta
        property="twitter:card"
        // content="https://static.fmgsuite.com/media/images/0fbf081d-183f-4e56-8bff-944869f2b11a.jpg"
        content="/images/alt-logo.jpeg"
      />
      <meta property="twitter:title" content="Flat Fee CIO Calculator" />
      <meta
        property="twitter:description"
        content="Our short survey can help you unlock your potential for tens of thousands of dollars in annual savings and higher net investment income year after year."
      />
      <SubmissionContext.Provider value={submissionValue}>
        <body
          className={roboto.className}
          style={{
            backgroundColor: "#fff",
            margin: 0,
            padding: 0,
            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
          }}
        >
          <ThemeRegistry>
            <Header />
            <Container
              sx={{
                background: "linear-gradient(to bottom, #424242, #9e9e9e)",
                minHeight: "100vh",
                minWidth: "100vw",
                padding: {
                  xs: "15px",
                  sm: "20px",
                  md: "25px",
                  lg: "30px",
                },
              }}
            >
              <Container
                sx={{
                  bgcolor: "white",
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "85%",
                    lg: "55%",
                  },
                  borderRadius: "5px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingTop: {
                    xs: "10px",
                    sm: "15px",
                    md: "20px",
                    lg: "25px",
                  },
                }}
              >
                {children}
                <Footer />
              </Container>
            </Container>
          </ThemeRegistry>
          <Analytics />
          <SpeedInsights />
          <div className="container">
            <Script src="https://www.googletagmanager.com/gtag/js?id=G-1PBDZ7F6LQ" />
            <Script id="google-analytics">
              {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', 'G-1PBDZ7F6LQ');
                `}
            </Script>
          </div>
        </body>
      </SubmissionContext.Provider>
    </html>
  );
}
