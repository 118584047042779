/*
 * Copyright (C) FLATFEECIO - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Matthew Woods mattwoods9170@gmail.com, April 2023
 *
 * This file is part of the FLATFEECIO Calculator project, owned by Don Rudolph.
 *
 * Unauthorized use of this file may result in legal consequences.
 * Proprietary and confidential information. Unauthorized use, reproduction,
 * or distribution of this file or any portion of it may be subject to civil
 * and criminal penalties, and the party responsible could be prosecuted.
 *
 * This file is provided without warranty. Use at your own risk.
 */

import { green, grey, red } from "@mui/material/colors";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";

let defaultTheme = createTheme({
  components: {
    MuiAlert: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.severity === "info" && {
            backgroundColor: "#60a5fa",
          }),
        }),
      },
    },
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
  },
  palette: {
    primary: {
      main: grey[900],
      dark: grey[800],
      light: grey[700],
      contrastText: grey[50],
    },
    // @ts-expect-error
    custom: {
      main: "#01597C",
      light: "#337a96",
      dark: "#00506f",
      contrastText: "#fff",
    },
    secondary: {
      main: green[700],
      dark: green[900],
      light: green.A400,
      contrastText: grey[900],
    },
    error: {
      main: red.A400,
      light: red.A200,
      dark: red.A700,
      contrastText: grey[50],
    },
  },
});

defaultTheme = responsiveFontSizes(defaultTheme);

export default defaultTheme;
