/*
 * Copyright (C) FLATFEECIO - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Matthew Woods mattwoods9170@gmail.com, April 2023
 *
 * This file is part of the FLATFEECIO Calculator project, owned by Don Rudolph.
 *
 * Unauthorized use of this file may result in legal consequences.
 * Proprietary and confidential information. Unauthorized use, reproduction,
 * or distribution of this file or any portion of it may be subject to civil
 * and criminal penalties, and the party responsible could be prosecuted.
 *
 * This file is provided without warranty. Use at your own risk.
 */

import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import * as React from "react";

export default function Footer(): React.JSX.Element {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      sx={{
        p: 2,
      }}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://www.flatfeecio.com/">
        Flat Fee CIO
      </Link>{" "}
      {new Date().getFullYear()}
      <br />
      Investment Advice offered through Founders Financial Alliance, LLC, a
      registered investment advisor.
    </Typography>
  );
}
